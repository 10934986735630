@import 'toast-mixin';

.green-toast {
  --background: var(--ion-color-primary-green);
  @include toast-message(8.6875rem);
}

.red-toast {
  --background: var(--ion-color-secondary-orange);
  @include toast-message(8.6875rem);
}

.push-toast {
  @include push-toast-content;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .green-toast {
    --max-width: 100%;
    @include toast-message(4.375rem);
  }

  .red-toast {
    --max-width: 100%;
    @include toast-message(4.375rem);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .green-toast {
    --max-width: 100%;
    @include toast-message(5rem);
  }

  .red-toast {
    --max-width: 100%;
    @include toast-message(5rem);
  }

  .push-toast {
    --max-width: 21.4375rem;
    --start: 27rem;
  }
}
