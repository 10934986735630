// ION-RADIO BUTTONS
ion-radio {
  --border-width: 0.0625rem;
  --inner-border-width: 0.625rem;
}

ion-radio::part(container) {
  border-color: var(--ion-color-gray-80);
  border-width: 0.0625rem;
  padding: 0.0625rem;
}

ion-radio.radio-checked::part(mark) {
  height: 95%;
  transform: none;
  transition: none;
  width: 95%;
}
