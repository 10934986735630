/* ------ GRID ------ */
ion-grid {
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 540px;
  --ion-grid-width-md: 720px;
  --ion-grid-width-lg: 960px;
  --ion-grid-width-xl: 1200px;
}

ion-col {
  padding: 0rem;
}

@include isDesktop {
  @media (hover: hover) and (pointer: fine) {
    ion-grid {
      --ion-grid-width-md: 900px;
      margin: 0rem auto;
      max-width: 1200px;
      padding: 0rem;
    }
  }
}

@include isMediumDesktop {
  @media (hover: hover) and (pointer: fine) {
    ion-grid {
      --ion-grid-width-md: 900px;
      padding: 0rem 0.625rem;
    }
  }
}

@include isSmallDesktop {
  @media (hover: hover) and (pointer: fine) {
    ion-grid {
      --ion-grid-width-md: 900px;
      padding: 0rem 1.25rem;
    }
  }
}

@include isTablet {
  ion-grid {
    --ion-grid-width-md: 100%;
    margin: 0rem auto;
    padding: 0rem 1.25rem;
    width: 100%;
  }
}

@include isMobile {
  ion-grid {
    --ion-grid-width-md: 100%;
    margin: 0rem auto;
    padding: 0rem 1rem;
    width: 100%;
  }
}
