@import 'modal-mixins';
@import '../breakpoints';

/* ---------- COMMON ---------- */
ion-modal.ion-modal-touch {
  --border-radius: 1rem;
  --height: 92%;
  --width: 100%;
  // zoom: 100% !important;

  &::part(handle) {
    background: var(--ion-color-gray-60);
    width: 3.125rem;
    top: 1rem;
  }

  @media (hover: none) and (pointer: coarse) {
    /* tablets (portrait and landscape) */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
      --height: 85%;
    }

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      --height: 92%;
    }

    @media only screen and (max-device-width: 845px) and (max-device-height: 400px) {
      --height: 92%;
    }
  }
}

ion-modal.ion-modal-touch-short {
  --border-radius: 1rem;
  --height: 40%;
  --width: 100%;
  // zoom: 100% !important;

  &::part(handle) {
    background: var(--ion-color-gray-60);
    width: 3.125rem;
    top: 1rem;
  }

  @media (hover: none) and (pointer: coarse) {
    /* tablets (portrait and landscape) */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 1) {
      --height: 35%;
    }

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      --height: 40%;
    }

    @media only screen and (max-device-width: 845px) and (max-device-height: 400px) {
      --height: 40%;
    }
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

.modal-loading-spinner {
  @include modal-desktop(7.5rem, 43.75rem);
}

/* ---------- CUSTOMERS ---------- */
.super-user-customers-modal-desktop {
  @include modal-desktop(48.75rem, 61.5rem);
}

/* ---------- LISTS ---------- */

.create-inventory-select-products-modal {
  @include modal-desktop(100%, 100%);
  &::part(content) {
    border-radius: 0rem;
  }
}

.download-print-desktop {
  @include modal-desktop(23.25rem, 61.5rem);
}

.inventory-information-modal-desktop {
  @include modal-desktop(32rem, 64.375rem);
}

.inventory-extended-cost-report-modal-desktop {
  @include modal-desktop(61.5rem, 65.625rem);
}

.inventory-custom-unit-desktop {
  @include modal-desktop(23.25rem, 36.875rem);
}
.inventory-custom-unit-tablet {
  @include modal-desktop(24.25rem, 36.875rem);
}

.inventory-cost-report-modal-desktop {
  @include modal-desktop(45.625rem, 61.5rem);
}

.inventory-cost-comparison-report-modal-desktop {
  @include modal-desktop(50.625rem, 61.5rem);
}

.create-edit-non-usf-vendor-modal-desktop {
  @include modal-desktop(44rem, 64.375rem);
}

.create-edit-list-note-modal-desktop {
  @include modal-desktop(22.125rem, 36.875rem);
  --min-height: 22.125rem;
  --max-height: 22.125rem;
}

.delete-vendor-modal-desktop {
  @include modal-desktop(26.25rem, 55rem);
}

.delete-item-non-usf-modal-desktop {
  @include modal-desktop(26.25rem, 51.5rem);
}

.delete-inventory-modal-desktop {
  @include modal-desktop(19.875rem, 40.75rem);
}

.simple-action-modal {
  @include modal-desktop(25rem, 55rem);
}

.simple-action-modal-nested {
  @include modal-desktop-shadow(25rem, 55rem);
}

.loading-no-button-modal {
  @include modal-desktop(18.5rem, 55rem);

  @include isSmallDesktop {
    @include modal-desktop(25rem, 55rem);
  }
}

.download-order-modal {
  @include modal-desktop(36rem, 61rem);
}

.simple-spinner-modal {
  @include modal-desktop(18rem, 39rem);
}

.replace-product-modal-desktop {
  @include modal-desktop(22.5rem, 37.5rem);
}

.import-list-errors-modal-desktop {
  @include modal-desktop(34.3125rem, 61.5rem);
}

.download-list-modal-desktop {
  @include modal-desktop(36.813rem, 61.5rem);
}

.print-list-modal-desktop {
  @include modal-desktop(31.9rem, 35.3rem);
}

.download-inventory-desktop {
  @include modal-desktop(43.4rem, 61.5rem);
}

.migration-confirmation-modal-desktop {
  @include modal-desktop(30.25rem, 61.5rem);
}

.pick-up-request-large {
  @include modal-desktop(23.75rem, 61.5rem);
}

.pick-up-request-small {
  @include modal-desktop(18.75rem, 39rem);
}

.delete-list-summary-desktop {
  @include modal-desktop(45rem, 64.375rem);
}

/* ---------- NOTIFICATIONS/ORDERS ---------- */

.add-products-modal-desktop {
  @include modal-desktop(61rem, 50rem);
}

.notification-filters-modal-desktop {
  @include modal-desktop(48.5rem, 64rem);
}

.more-options-modal {
  @include modal-desktop(24.6875rem, 36.875rem);
}

.submit-order-mobile .modal-wrapper,
.submit-order-mobile .modal-shadow {
  --height: 90% !important;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  bottom: 0;
  position: absolute;
  top: unset;
  width: 100%;
}

.custom-messages-modal {
  @include modal-desktop(46.125rem, 61.5rem);
}

.invite-new-user-modal {
  @include modal-desktop(31rem, 36.875rem);
}

.invite-existing-user-modal {
  @include modal-desktop(38rem, 55rem);
}

/* ---------- PDP ---------- */
.full-page-modal {
  &::part(content) {
    height: 100% !important;
    width: 100% !important;
    min-height: 100%;
  }
}

/* ---------- SHARE VIA EMAIL ---------- */
.share-via-email-modal-desktop {
  @include modal-desktop(42.8125rem, 55rem);
}

.share-via-email-modal-mobile {
  --height: 90% !important;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  bottom: 0;
  position: absolute;
  top: unset;
  width: 100%;
}

/* ---------- SEARCH ---------- */
.search-filters-desktop {
  @include modal-desktop(36.875rem, 50rem);
}

/* --- CALENDAR TABLET AND DESKTOP --- */
.calendar-modal-desktop {
  @include modal-desktop(45rem, 37.5rem);
  --max-width: 90%;
}

/* Error Modal  */
.error-modal {
  @include isDesktop {
    --height: 34.75rem;
    --width: 53.125rem;
    --border-radius: 1rem;
  }

  @include isTablet {
    --height: 70%;
    --width: 90%;
    --border-radius: 1rem;
  }

  @include isMobile {
    --height: 90%;
    --max-width: 90%;
    --width: 90%;
    --border-radius: 1rem;
  }
}

.promote-app-modal {
  @include modal-desktop(35rem, 18rem);
  // zoom: 100% !important;

  @media only screen and (max-device-height: 29rem) {
    @include modal-desktop(92vh, 60vw);
  }
}

/* Customer Dropdown Touch - Adjust for Virtual Scroll */
ion-content.ion-content-modal-mobile-virtual-scroll {
  .customer-dropdown-touch {
    .cdk-virtual-scroll-content-wrapper {
      padding: 1.25rem 1.25rem 0 1.25rem;
    }
  }
}
ion-content.ion-content-modal-tablet-virtual-scroll {
  .customer-dropdown-touch {
    .cdk-virtual-scroll-content-wrapper {
      padding: 1.25rem 3.75rem 0 3.75rem;
    }
  }
}

.select-customers-desktop-modal {
  @include modal-desktop(42.5rem, 61.5rem);
}

/* -------- FILTERING -------- */

.usf-filtering-modal {
  @include modal-desktop(46.5rem, 61.5rem);
}

/* -------- PAYMENTS -------- */

.help-modal {
  @include modal-desktop(40rem, 61.5rem);
}

/* ---------- COMMON DESKTOP MODAL CLASSES FOR SIZE ---------- */
/*------@mixin responsive-modal-desktop
($minHeight, $maxHeight, $minWidth, $maxWidth------------------*/

.modal-desktop-size-xl {
  @include responsive-modal-desktop(70%, 90%, 70%, 75%);
  overflow-y: scroll;
}

.modal-calendar-desktop-size-xl {
  @include responsive-modal-desktop(75%, 85%, 50%, 55%);
  overflow-y: scroll !important;
}

.modal-desktop-size-lg {
  @include responsive-modal-desktop(50%, 90%, 60%, 70%);
  overflow-y: scroll;
}

.modal-calendar-desktop-size-lg {
  @include responsive-modal-desktop(75%, 85%, 40%, 40%);
  overflow-y: scroll !important;
}

.modal-desktop-size-md {
  @include responsive-modal-desktop(42%, 52%, 60%, 70%);
  overflow-y: scroll;
}

.modal-desktop-size-sm {
  @include responsive-modal-desktop(50%, 50%, 40%, 40%);
  overflow-y: scroll;
}
