/* This file contains common overwritten styles for power reviews in general */
/* imports works for css file */

/* Overwrite starts style */

.pr-star-v4-100-filled {
  background-image: url('/assets/images/100-percent-star-v2.svg') !important;
}
.pr-star-v4-75-filled {
  background-image: url('/assets/images/75-percent-star-v2.svg') !important;
}
.pr-star-v4-50-filled {
  background-image: url('/assets/images/50-percent-star-v2.svg') !important;
}
.pr-star-v4-25-filled {
  background-image: url('/assets/images/25-percent-star-v2.svg') !important;
}
.pr-star-v4-0-filled {
  background-image: url('/assets/images/0-percent-star.svg') !important;
}

// Filtering PWR dropdown : Q&A and Reviews

@mixin pwr-dropdown {
  border-radius: 3.125rem;
  border: 0.01rem solid var(--ion-color-primary-green) !important;
  display: inline-flex;
  padding-top: 0;

  font-family: 'SF Pro Regular';
  color: var(--ion-color-primary-green);
  .sort-by {
    padding-left: 1rem;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    font-family: 'SF Pro Regular';
    color: var(--ion-color-primary-green) !important;
    white-space: nowrap;
  }
  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 0.0625rem;
    text-overflow: '' !important;
    min-width: auto !important;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--ion-color-white);
    border: 0 solid var(--ion-color-primary-green) !important;
    background: url('/assets/icon/chevron-down-green.svg') no-repeat scroll
      0.07rem 0.07rem;
    background-position: right;
    margin-right: 1rem;
  }

  @include isTablet {
    float: right;
  }
}
