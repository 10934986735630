/* Common Scroll To Top Button on Pages */
.scroll-to-top-button {
  position: absolute;
  bottom: 2.5rem;
  right: 5.44rem;

  ion-button {
    min-width: 5.1875rem !important;
  }

  @include isTablet {
    bottom: 2%;
  }

  @include isMobile {
    bottom: 5.5rem;
    right: 3.44rem;
  }
}
