/* ---------- BUTTONS ---------- */

ion-button.disable-outline-button {
  color: var(--ion-color-gray-60);
  --border-color: var(--ion-color-gray-60);

  ion-icon {
    color: var(--ion-color-gray-60);
  }
}

ion-button:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

:root {
  ion-button.usf-fill-green-button,
  ion-button.usf-fill-green-dsktp-tblt-modal-button,
  ion-button.usf-fill-green-mobile-modal-button,
  ion-button.usf-outline-green-button,
  ion-button.usf-outline-green-dsktp-tblt-modal-button,
  ion-button.usf-outline-green-mobile-modal-button,
  ion-button.usf-fill-orange-button,
  ion-button.usf-fill-orange-dsktp-tblt-modal-button,
  ion-button.usf-fill-orange-mobile-modal-button,
  ion-button.usf-outline-orange-button,
  ion-button.usf-outline-orange-dsktp-tblt-modal-button,
  ion-button.usf-outline-orange-mobile-modal-button,
  ion-button.usf-fill-disabled-button,
  ion-button.usf-fill-disabled-dsktp-tblt-modal-button,
  ion-button.usf-fill-disabled-mobile-modal-button,
  ion-button.usf-outline-disabled-button,
  ion-button.usf-fill-white-button,
  ion-button.usf-fill-white-dsktp-tblt-modal-button,
  ion-button.usf-fill-white-mobile-modal-button {
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --border-radius: 2rem !important;
    --box-shadow: none;
    --opacity: 1;
    --padding-bottom: 0;
    --padding-end: 1rem;
    --padding-start: 1rem;
    --padding-top: 0;
    --ripple-color: transparent;
    --transition: transparent;
    --ion-font-family: 'SF Pro Medium', sans-serif !important;
    font-size: $font-size-14-px;
    min-height: 2rem;
    letter-spacing: 0;
    text-transform: capitalize;
    white-space: nowrap;
  }

  ion-button.usf-fill-green-button,
  ion-button.usf-fill-green-dsktp-tblt-modal-button,
  ion-button.usf-fill-green-mobile-modal-button {
    --background: var(--ion-color-primary);
    --background-activated: var(--ion-color-primary-green-hover);
    --background-focused: var(--ion-color-primary-green-hover);
    --background-hover: var(--ion-color-primary-green-hover);
    --border-color: transparent;
    --color: var(--ion-color-white);
    --color-activated: var(--ion-color-white);
    --color-focused: var(--ion-color-white);
    --color-hover: var(--ion-color-white);
  }

  ion-button.usf-outline-green-button,
  ion-button.usf-outline-green-dsktp-tblt-modal-button,
  ion-button.usf-outline-green-mobile-modal-button {
    --background: var(--ion-color-white);
    --background-activated: var(--ion-color-primary-green-20);
    --background-focused: var(--ion-color-primary-green-20);
    --background-hover: var(--ion-color-primary-green-20);
    --border-color: var(--ion-color-primary);
    --border-width: 0.0625rem;
    --border-style: solid;
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary);
    --color-hover: var(--ion-color-primary);
  }

  ion-button.usf-fill-orange-button,
  ion-button.usf-fill-orange-dsktp-tblt-modal-button,
  ion-button.usf-fill-orange-mobile-modal-button {
    --background: var(--ion-color-secondary-orange);
    --background-activated: var(--ion-color-secondary-orange-hover);
    --background-focused: var(--ion-color-secondary-orange-hover);
    --background-hover: var(--ion-color-secondary-orange-hover);
    --border-color: transparent;
    --color: var(--ion-color-white);
    --color-activated: var(--ion-color-white);
    --color-focused: var(--ion-color-white);
    --color-hover: var(--ion-color-white);
  }

  ion-button.usf-outline-orange-button,
  ion-button.usf-outline-orange-dsktp-tblt-modal-button,
  ion-button.usf-outline-orange-mobile-modal-button {
    --background: var(--ion-color-white);
    --background-activated: var(--ion-color-secondary-orange-5);
    --background-focused: var(--ion-color-secondary-orange-5);
    --background-hover: var(--ion-color-secondary-orange-5);
    --border-color: var(--ion-color-secondary-orange);
    --border-width: 0.0625rem;
    --border-style: solid;
    --color: var(--ion-color-secondary-orange);
    --color-activated: var(--ion-color-secondary-orange);
    --color-focused: var(--ion-color-secondary-orange);
    --color-hover: var(--ion-color-secondary-orange);
  }

  ion-button.usf-fill-disabled-button,
  ion-button.usf-fill-disabled-dsktp-tblt-modal-button,
  ion-button.usf-fill-disabled-mobile-modal-button {
    --background: var(--ion-color-gray-40);
    --background-activated: var(--ion-color-gray-40);
    --background-focused: var(--ion-color-gray-40);
    --background-hover: var(--ion-color-gray-40);
    --border-color: transparent;
    --color: var(--ion-color-gray-80);
    --color-activated: var(--ion-color-gray-80);
    --color-focused: var(--ion-color-gray-80);
    --color-hover: var(--ion-color-gray-80);
    cursor: not-allowed;
    pointer-events: none;
  }

  ion-button.usf-outline-disabled-button {
    --background: var(--ion-color-gray-20);
    --background-activated: var(--ion-color-gray-20);
    --background-focused: var(--ion-color-gray-20);
    --background-hover: var(--ion-color-gray-20);
    --border-color: transparent;
    --border-width: 0.0625rem;
    --color: var(--ion-color-gray-80);
    --color-activated: var(--ion-color-gray-80);
    --color-focused: var(--ion-color-gray-80);
    --color-hover: var(--ion-color-gray-80);
    cursor: not-allowed;
    pointer-events: none;
  }

  ion-button.usf-fill-white-button,
  ion-button.usf-fill-white-dsktp-tblt-modal-button,
  ion-button.usf-fill-white-mobile-modal-button {
    --background: var(--ion-color-white);
    --background-activated: var(--ion-color-white-shade);
    --background-focused: var(--ion-color-white-shade);
    --background-hover: var(--ion-color-white-shade);
    --border-color: var(--ion-color-primary);
    --border-width: 0.0625rem;
    --border-style: solid;
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary);
    --color-hover: var(--ion-color-primary);
  }
}
// GENERIC BUTTON WIDTHS
ion-button.usf-fill-green-button,
ion-button.usf-outline-green-button,
ion-button.usf-fill-orange-button,
ion-button.usf-outline-orange-button,
ion-button.usf-fill-disabled-button,
ion-button.usf-outline-disabled-button,
ion-button.usf-fill-white-button {
  min-width: 6.25rem;
}

// MODAL BUTTONS WIDTHS (DESKTOP, TABLET)
ion-button.usf-fill-green-dsktp-tblt-modal-button,
ion-button.usf-outline-green-dsktp-tblt-modal-button,
ion-button.usf-fill-disabled-dsktp-tblt-modal-button,
ion-button.usf-fill-orange-dsktp-tblt-modal-button,
ion-button.usf-outline-orange-dsktp-tblt-modal-button,
ion-button.usf-fill-white-dsktp-tblt-modal-button {
  min-width: 12.5rem;
}

// MODAL BUTTONS WIDTHS (MOBILE)
ion-button.usf-fill-green-mobile-modal-button,
ion-button.usf-outline-green-mobile-modal-button,
ion-button.usf-fill-disabled-mobile-modal-button,
ion-button.usf-fill-orange-mobile-modal-button,
ion-button.usf-outline-orange-mobile-modal-button,
ion-button.usf-fill-white-mobile-modal-button {
  min-width: 9.5rem;
}

ion-button.usf-green-link-button,
ion-button.usf-red-link-button,
ion-button.usf-link-disabled-button {
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-color: transparent;
  --border-width: 0.0625rem;
  --border-style: solid;
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --border-radius: 0 !important;
  --box-shadow: none;
  --opacity: 1;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --ripple-color: transparent;
  --transition: transparent;
  --ion-font-family: 'SF Pro Medium', sans-serif !important;
  font-size: $font-size-14-px;
  min-height: 2rem;
  width: auto;
  letter-spacing: 0;
  text-transform: capitalize;
}

ion-button.usf-green-link-button {
  --color: var(--ion-color-primary);
  --color-activated: var(--ion-color-primary);
  --color-focused: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary);
}

ion-button.usf-red-link-button {
  --color: var(--ion-color-secondary-orange);
  --color-activated: var(--ion-color-secondary-orange);
  --color-focused: var(--ion-color-secondary-orange);
  --color-hover: var(--ion-color-secondary-orange);
}

ion-button.usf-link-disabled-button {
  --color: var(--ion-color-gray-40);
  --color-activated: var(--ion-color-gray-40);
  --color-focused: var(--ion-color-gray-40);
  --color-hover: var(--ion-color-gray-40);
  cursor: not-allowed;
  pointer-events: none;
}

// IONIC REWRITE
.button-clear:hover,
.button-outline:hover {
  opacity: 1 !important;
}

.button-clear {
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-color: transparent;
  --ripple-color: transparent;
  --transition: transparent;
}
