ion-accordion-group > ion-accordion > ion-item[slot='header'] {
  --border-width: 0;
  --padding-start: 0;
  --padding-end: 0;
}

ion-accordion-group > ion-accordion [slot='content'] {
  --background: var(--ion-color-gray-page-background) !important;
}

#quick-entry-accordion {
  ion-accordion-group > ion-accordion > ion-item[slot='header'] {
    //border: 1px solid gray;
    border-radius: 0.5rem;
    --padding-start: 0;
    --padding-end: 0;
    --ion-color-base: white !important;
  }

  ion-accordion-group > ion-accordion [slot='content'] {
    --background: transparent !important;
  }
}
