/* Light Green Note Banner */
ion-item.banner-note {
  --background: var(--ion-color-primary-green-20) !important;
  --border-style: none !important;
  --border-radius: 0.5rem !important;
  --color: var(--ion-color-black) !important;
  --inner-padding-bottom: 0.625rem !important;
  --inner-padding-top: 0.625rem !important;
  --padding-end: 1.125rem !important;
  --padding-start: 1.125rem !important;
  cursor: default;

  ion-label {
    line-height: 1.3;
    white-space: break-spaces;
    font-size: $font-size-12-px !important;

    .note-bold {
      font-family: 'SF Pro Bold';
      text-transform: uppercase;
    }

    .note-regular {
      font-family: 'SF Pro Regular';
    }

    .note-fancy {
      font-family: 'SF Pro RegularItalic';
    }
  }
}

@include isMobile {
  ion-label {
    overflow-wrap: break-word !important;
  }
}
