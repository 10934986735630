//  Default Ionic Breakpoints
$mobile-small-min: 320px;
$mobile-small-max: 359px;
$mobile-large-min: 376px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-med: 820px;
$tablet-max: 1368px;
$desktop-small-max: 959px;
$desktop-responsive-min: 900px;
$desktop-med-min: 960px;
$desktop-min: 1181px;
$desktop-med-max: 1199px;
$desktop-large: 1665px;
$desktop-large-max: 1900px;

$isMobile: 'max-device-width:#{$mobile-max}';
$isMobileAndTablet: 'max-device-width:#{$tablet-max}';
$isTabletAndDesktop: 'min-device-width:#{$tablet-min}';
$isDesktop: 'min-device-width:#{$desktop-min}';

// Desktop variants
@mixin isDesktopLargeMax {
  @media only screen and (min-device-width: #{$desktop-large-max}) {
    @content;
  }
}

@mixin isDesktopLarge {
  @media only screen and (min-device-width: #{$desktop-large}) {
    @content;
  }
}

@mixin isDesktop {
  @media only screen and (min-device-width: #{$desktop-min}) {
    @content;
  }
}
@mixin isMediumDesktop {
  @media only screen and (min-width: #{$desktop-med-min}) and
                         (max-width: #{$desktop-med-max}) {
    @content;
  }
}
@mixin isSmallDesktop {
  @media only screen and (max-width: #{$desktop-small-max}) {
    @content;
  }
}
@mixin isSmallResponsiveDesktop {
  @media only screen and (min-width: #{$desktop-responsive-min}) and
                         (max-width: #{$desktop-small-max}) {
    @content;
  }
}

// Tablet variants
@mixin isSmallTablet {
  @media only screen and (min-device-width: #{$mobile-large-min}) and
                         (max-device-width: #{$mobile-max}) {
    @content;
  }
}
@mixin isTablet {
  @media only screen and (min-device-width: #{$tablet-min}) and
                         (max-device-width: #{$tablet-max}) {
    @content;
  }
}
@mixin isMediumTablet {
  @media only screen and (min-device-width: #{$tablet-min}) and
                         (max-device-width: #{$desktop-med-min}) {
    @content;
  }
}
@mixin isLargeTablet {
  @media only screen and (min-device-width: #{$desktop-med-min}) and
                         (max-device-width: #{$tablet-max}) {
    @content;
  }
}
// mobile variants
@mixin isMobile {
  @media only screen and (max-device-width: #{$mobile-max}) {
    @content;
  }
}
@mixin isSmallMobile {
  @media only screen and (min-device-width: #{$mobile-small-min}) and
                         (max-device-width: #{$mobile-small-max}) {
    @content;
  }
}

@mixin isLargeMobile {
  @media only screen and (min-device-width: #{$mobile-large-min}) {
    @content;
  }
}

// Mixed variants
@mixin isMobileAndTablet {
  @media only screen and (max-device-width: #{$tablet-max}) {
    @content;
  }
}
@mixin isTabletAndDesktop {
  @media only screen and (min-device-width: #{$tablet-min}) {
    @content;
  }
}
