@import '../breakpoints';

ion-loading.visibilty-hide {
  display: none !important;
  z-index: -9999 !important;
}

.loading-screen-content {
  background-color: var(--ion-color-gray-page-background);
  padding: 1rem;
  font-size: $font-size-14-px;
  font-family: 'SF Pro Bold', sans-serif;
  min-height: 32.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ion-spinner {
    height: 2.1rem;
    width: 2.1rem;
    margin: 0 0 10px;
    transform: scale(1.6);
  }
}

#ion-content-load-screen #mobile,
#ion-content-load-screen #tablet,
#ion-content-load-screen #desktop .gray-content-bg {
  position: relative;
  z-index: 1;
  display: none;
}

ion-content.loaded {
  .loading-screen-content {
    display: none;
  }

  #mobile,
  #tablet,
  #desktop .gray-content-bg {
    display: block !important;
  }
}

@include isTablet {
  #ion-content-load-screen #mobile {
    display: none !important;
  }
}

@include isMobile {
  #ion-content-load-screen #tablet {
    display: none !important;
  }
}
