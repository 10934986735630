// d - decimal
$font-size-5-px: 0.3125rem; //5px
$font-size-7-d5-px: 0.4688rem; //7.5px
$font-size-8-px: 0.5rem; //8px
$font-size-8-d4-px: 0.525rem; //8.4px
$font-size-9-d6-px: 0.6rem; //9.6px
$font-size-10-px: 0.625rem; //10px
$font-size-11-px: 0.6875rem; //11px
$font-size-11-d008-px: 0.688rem; //11.008px
$font-size-12-px: 0.75rem; //12px
$font-size-12-d8-px: 0.8rem; //12.8px
$font-size-13-d008-px: 0.813rem; //13.008px
$font-size-13-px: 0.8125rem; //13px
$font-size-14-px: 0.875rem; //14px
$font-size-15-px: 0.9375rem; //15px
$font-size-16-px: 1rem; //16px
$font-size-17-px: 1.0625rem; //17px
$font-size-18-px: 1.125rem; //18px
$font-size-19-px: 1.1875rem; //19px
$font-size-20-px: 1.25rem; //20px
$font-size-21-px: 1.3125rem; //21px
$font-size-22-px: 1.375rem; //22px
$font-size-24-px: 1.5rem; //24px
$font-size-25-px: 1.5625rem; //25px
$font-size-26-px: 1.625rem; //26px
$font-size-28-px: 1.75rem; //28px
$font-size-30-px: 1.875rem; //30px
$font-size-32-px: 2rem; //32px
$font-size-34-px: 2.125rem; //34px
$font-size-35-px: 2.1875rem; //35px
$font-size-36-px: 2.25rem; //36px
$font-size-38-px: 2.375rem; //38px
$font-size-40-px: 2.5rem; //40px
$font-size-42-px: 2.625rem; //42px
$font-size-45-px: 2.8125rem; //45px
$font-size-48-px: 3rem; //48px
$font-size-60-px: 3.75rem; //60px
$font-size-64-px: 4rem; //64px
$font-size-70-px: 4.375rem; //70px
$font-size-72-px: 4.5rem; //72px
$font-size-58-px: 3.625rem; //58px
$font-size-43-px: 2.688rem; //43px

$font-size-0-d8-em: 0.8em;
$font-size-1-d2-em: 1.2em;
$font-size-1-d4-em: 1.4em;
$font-size-1-d5-em: 1.5em;
$font-size-3-em: 3em;
