/* ------- TOOLTIP --------- */
.tooltip-recent-purchase-hover,
.tooltip-catch-weight-hover {
  position: absolute;
  z-index: 999;
  width: 10rem;
  top: calc(100% + 0.25rem);
  left: 55%;
  margin-left: -5rem; // Should be half width
}

.tooltip-catch-weight-hover-card {
  font-family: 'SF Pro Bold', sans-serif;
  position: absolute;
  z-index: 999;
  top: 40%;
  left: 65%;
}

.tooltip-split-catch-weight-hover-card {
  font-family: 'SF Pro Bold', sans-serif;
  position: absolute;
  z-index: 999;
  top: 40%;
  left: 20%;
}

.tooltip-recent-purchase-popover,
.tooltip-catch-weight-popover {
  .recent-purchase-container svg.arrow-top,
  .catch-weight-container svg.arrow-top {
    display: none;
  }

  .popover-content {
    width: fit-content !important;
    background: transparent !important;
  }

  .popover-arrow.sc-ion-popover-ios::after {
    background: var(--ion-color-primary) !important;
  }
}

.rounded-table {
  border-collapse: separate !important;

  .inner-content {
    border-collapse: separate;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0.625rem;
    min-width: 5rem;
    text-align: center;

    div {
      font-family: 'SF Pro Regular';
      font-size: 0.75rem;

      &:first-of-type {
        margin-bottom: 0.5rem;
      }
    }
  }
}
