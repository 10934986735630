/* ------ TOOLBAR ------- */

ion-toolbar ion-icon {
  font-size: 1.8rem !important;
}

ion-toolbar.toolbar-footer {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0 !important;
}

.toolbar-container,
.toolbar-label,
.toolbar-background {
  --background: #ffffff !important;
}

ion-tab-bar,
.toolbar-footer {
  --background: #ffffff !important;
}

html.ios ion-modal ion-footer ion-toolbar,
html.ios ion-modal ion-toolbar {
  padding-top: 6px;
  --background: white !important;
}
