mat-radio-group.mat-radio-options {
  .mat-radio-button.mat-accent .mat-radio-outer-circle {
    border-color: var(--ion-color-gray-60);
    border-width: 0.0625rem;
    height: 1.375rem;
    width: 1.375rem;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
    background-color: var(--ion-color-primary-green);
    height: 1.375rem;
    width: 1.375rem;
    transform: scale(0.8);
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--ion-color-primary-green);
  }

  .mat-ripple-element {
    display: none !important;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-header {
  height: auto;
  padding: 0 !important;
}

// Needs Refactor - Custom Mat Radio Buttons
#edit-quantity {
  .mat-radio-label-content {
    white-space: pre-wrap !important;
    font-size: $font-size-14-px;
    margin-top: 0.625rem;
    font-family: 'SF Pro Regular', sans-serif !important;
  }
}

#more-options-desktop {
  .mat-radio-options {
    display: flex;
    flex-direction: column;
  }

  .mat-radio-label-content {
    white-space: pre-wrap !important;
    font-size: $font-size-14-px;
    margin-top: 0;
    font-family: 'SF Pro Regular', sans-serif !important;
  }

  .mat-radio-label {
    margin-bottom: 2.625rem;
  }
}

#edit-quantity-tablet,
#edit-quantity-mobile {
  .mat-radio-label-content {
    font-size: $font-size-12-px;
    font-family: 'SF Pro Regular', sans-serif !important;
    margin-right: 0.3125rem;
  }

  .mat-radio-label {
    margin-bottom: 0.625rem;
  }
}
