ion-modal {
  ::-webkit-scrollbar {
    width: 0.875rem;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--ion-color-gray-60);
    border: 0.25rem solid transparent;
    border-radius: 0.625rem;
  }
}
