@import '../font-sizes';
@import '../breakpoints';

html {
  font-size: 16px; //Do not change PX here. This sets the REM base size.
}

body {
  overflow-x: auto;
  position: unset;
}

input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0 0 0 30px white inset !important;
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0 0 0 30px white inset !important;
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

::-ms-reveal {
  display: none !important;
}

.gray-content-bg {
  background: var(--ion-color-gray-page-background) !important;
  --background: var(--ion-color-gray-page-background) !important;
}

.white-content-bg {
  background: var(--ion-color-white) !important;
  --background: var(--ion-color-white) !important;
}

/* ---------- FONT WEIGHTS ---------- */
.bold-font-weight {
  font-family: 'SF Pro Bold', sans-serif !important;
}

.medium-font-weight {
  font-family: 'SF Pro Medium', sans-serif !important;
}

.regular-font-weight {
  font-family: 'SF Pro Regular', sans-serif !important;
}

.sf-pro-display {
  font-size: $font-size-10-px;
  font-family: 'SF Pro Display', sans-serif !important;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

/* ---------- SWIPER JS ---------- */
swiper-container {
  width: 100%;
  height: 100%;
}

swiper-slide img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

.ion-invalid.ion-touched ion-label {
  color: var(--ion-color-secondary-orange);
}

.mini-border {
  color: var(--ion-color-gray-60);
  margin: 0rem 0.625rem;
}

.top-button {
  bottom: 1.25rem;
  position: absolute;
  right: 2.5rem;
  z-index: 1;
}

// Remove Arrows on Numeric Inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea:focus,
input:focus {
  outline: none;
}

app-message {
  height: 100%;
}

.page-title-oswald {
  margin-top: 2rem;
  font-size: 48px;
  line-height: normal;
  font-family: 'Oswald Bold';
  text-transform: uppercase;
}

.page-subtitle {
  margin-top: 1.5rem;
  line-height: normal;
  font-family: 'SF Pro Bold', sans-serif !important;
  font-size: 28px;
}

p {
  font-size: $font-size-14-px;
}

.ion-margin-vertical-half {
  margin: 0.5rem 0;
}

// Custom Borders on Customer Dropdown
.header-mob-no-profile-letter {
  margin-left: 3.125rem;
  padding-left: 0 !important;
  border-top: 1px solid var(--ion-color-gray-20) !important;
}

.headerProfileLetter {
  border-top: 1px solid var(--ion-color-gray-20) !important;
}

/* ---------- LISTS ---------- */
.cancel-button {
  --button-color: var(--ion-color-secondary-orange);
  --button-color-activated: var(--ion-color-secondary-orange);
  font-family: 'SF Pro Bold' !important;
}

.list-groups-button {
  background: var(--ion-color-white);
  border-radius: 0.5rem;
  box-shadow: 0 0.0938rem 0.3125rem 0 rgb(0 0 0 / 15%);
  color: var(--ion-color-black);
  height: 4.1875rem;
  margin-bottom: 0.625rem;
  padding: 0rem 0.9375rem;

  .button-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  span,
  p {
    flex-shrink: 0;
    font-family: 'SF Pro Medium';
    font-size: $font-size-16-px;
    line-height: 1.875rem;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ion-icon {
    flex-shrink: 0;
    font-size: $font-size-26-px;
  }
}

.close-modal-icon {
  --ionicon-stroke-width: 3rem;
  align-self: flex-end;
  cursor: pointer;
  color: var(--ion-color-primary-green);
  font-size: $font-size-32-px;
  margin: 1.5625rem 1.5625rem 0rem 0rem;
  z-index: 1;
}

#no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: transparent;
  --background-focused: transparent;
  --background-focused-opacity: transparent;
  --background-hover: transparent;
  --background-hover-opacity: transparent;
}

.gray-border-bottom {
  height: 1px;
  width: 100%;
  background-color: var(--ion-color-gray-60);
}

.help-icon {
  border: 0rem;
  box-shadow: 0 0 0;
  margin-top: -4.0625rem;
  padding: 0rem;
  position: fixed;
  right: 2.5rem;
  bottom: 2.5rem;
  z-index: 9999;

  @include isMobile {
    right: 1rem;
    bottom: 1.145rem;
    transform: translate(0, 0.125rem);
  }

  @include isTablet {
    bottom: 2%;
    transform: translate(0, 0.125rem);
  }

  img {
    margin: 0rem auto;
    padding: 0rem;
    text-align: left;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
}

.usf-product-card-promotions {
  align-items: center;
  background: var(--ion-color-primary-green-20);
  border-radius: 0.375rem;
  display: flex;
  padding: 0.25rem 0.625rem;

  span {
    color: var(--ion-color-black);
    display: -webkit-box;
    font-family: 'SF Pro Bold', sans-serif;
    font-size: $font-size-12-px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.help-center-link {
  font-family: 'SF Pro Medium', sans-serif;
  text-decoration: none;
  &:hover {
    color: var(--ion-color-primary-green-hover);
  }
}

@include isDesktop {
  ion-content {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

@include isTablet {
  ion-content {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

@include isMobile {
  ion-content {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

/* ---- INVENTORY ---- */
.popover-information {
  @include isDesktop {
    white-space: inherit !important;
    text-overflow: unset !important;
    margin-right: 0rem;
  }

  @include isMobileAndTablet {
    color: var(--ion-color-gray-80) !important;
    font-size: $font-size-14-px !important;
    font-family: 'SF Pro Regular' !important;
  }
}

/* ---- PAYMENTS ---- */
.remove-button-disable {
  color: gray !important;
  pointer-events: none;
}

/* ---- MY ORDER CARDS ORDER READY STATUS ----- */
.order-ready {
  background-color: var(--ion-color-gray-30) !important;
  border: 0.0625rem solid var(--ion-color-gray-40) !important;
  box-shadow: none !important;
}

/* ---- INPUT ADJUSTMENTS FOR v7----- */
.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 1.875rem !important;
  min-width: 3.75rem !important;
}

/* ---- INPUT ADJUSTMENTS FOR FUTURE v8 UPDATES----- */
.input-label-placement-stacked.sc-ion-input-ios-h,
.label-text-wrapper.sc-ion-input-ios {
  transform: none !important;
}

#usf-line-input-style {
  .input-label-placement-stacked.sc-ion-input-ios-h,
  .label-text-wrapper.sc-ion-input-ios {
    transform: none !important;
    font-size: 1rem;
    font-family: 'SF Pro Medium', sans-serif;
    color: var(--ion-color-black);
    display: block;
    margin-bottom: 1.75rem;
  }
  .input-wrapper.sc-ion-input-ios {
    padding-top: 0 !important;
  }
}

#usf-line-input-style-readonly {
  .input-label-placement-stacked.sc-ion-input-ios-h,
  .label-text-wrapper.sc-ion-input-ios {
    transform: none !important;
    font-size: 1rem;
    font-family: 'SF Pro Medium', sans-serif;
    color: var(--ion-color-gray-80);
    display: block;
    margin-bottom: 1.75rem;
  }
  .input-label-placement-stacked.sc-ion-input-ios-h
    .input-wrapper.sc-ion-input-io {
    padding-top: 0 !important;
  }
}

ion-label {
  white-space: nowrap;
}
