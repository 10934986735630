/*----- DRAG AND DROP ------*/
$drop-zone-border: dashed 0.125rem var(--ion-color-yellow);

.drop-zone-down {
  border-bottom: $drop-zone-border;
  cursor: move;
}

.drop-zone-up {
  border-top: $drop-zone-border;
  cursor: move;
}

.drag-drop-aura {
  border: dashed 0.125rem var(--ion-color-yellow);
  box-shadow: 0rem 0.125rem 0.0625rem -0.0625rem var(--ion-color-yellow),
    0rem 0.0625rem 0.0625rem 0rem var(--ion-color-yellow),
    0rem 0.0625rem 0.1875rem 0rem var(--ion-color-yellow);
}

.disable-footer-pointer-events {
  pointer-events: none;
}

.selected-edit:hover {
  cursor: move;
}
