/* POPOVER MIXINS - DO NOT TOUCH */

// WEB DEFAULTS
$default-web-customer-dropdown-width: 26.125rem;
$default-web-popover-width: 14.25rem;
$default-scrollbar-width: 0.875rem;

/* CUSTOMER DROP DOWN WEB */
@mixin popover-customer($width) {
  ion-backdrop {
    cursor: default !important;
  }
  &::part(arrow) {
    --background: var(--ion-color-primary-green) !important;
  }
  &::part(content) {
    --background: var(--ion-color-white);
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-primary-green);
    min-width: $width;
  }
  .popover-arrow::after {
    background-color: var(--ion-color-primary-green) !important;
  }
}

@mixin popover-web(
  $width: $default-web-popover-width,
  $scroll-bar-width: $default-scrollbar-width
) {
  --box-shadow: none;
  margin-top: 0.25rem;

  ::-webkit-scrollbar {
    width: $scroll-bar-width;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--ion-color-primary-green);
    border: 0.25rem solid rgba(0, 0, 0, 0);
    border-radius: 0.625rem;
  }

  .popover-viewport {
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;

    ion-list {
      padding: 0.75rem 0rem;

      ion-item {
        --background: transparent;
        --border-color: var(--ion-color-primary-green);
        --color: var(--ion-color-primary-green);
        --max-height: 3.125rem;
        --min-height: 3.125rem;
        --padding-end: 0rem;
        --padding-start: 0.625rem;
        background: transparent;
        color: var(--ion-color-primary-green);
        cursor: pointer;
        margin: 0rem 0.75rem;

        &.non-button {
          --color: var(--ion-color-black);
          color: var(--ion-color-black);
          cursor: default;
          max-width: 15.2rem;
          text-overflow: unset;
          text-wrap: unset;
        }

        ion-label {
          font-family: 'SF Pro Medium', sans-serif;
          font-size: $font-size-16-px;
          white-space: nowrap;
        }
      }

      ion-item:hover {
        --background: var(--ion-color-primary-green-5);
        background: var(--ion-color-primary-green-5);
        --color: var(--ion-color-primary-green);
        color: var(--ion-color-primary-green);

        &.non-button {
          --background: transparent;
          background: transparent;
          --color: var(--ion-color-black);
          color: var(--ion-color-black);
        }
      }
    }
  }

  &::part(arrow) {
    --background: var(--ion-color-primary-green) !important;
  }
  .popover-arrow::after {
    background-color: var(--ion-color-primary-green) !important;
  }
  &::part(content) {
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-primary-green);
    margin-top: -0.0625rem;
    overflow-x: hidden;
    overflow-y: hidden;
    width: $width;
  }
}

@mixin popover-header-web(
  $width: $default-web-popover-width,
  $scroll-bar-width: $default-scrollbar-width
) {
  --box-shadow: none;
  margin-top: 0.25rem;

  ::-webkit-scrollbar {
    width: $scroll-bar-width;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--ion-color-primary-green);
    border: 0.25rem solid rgba(0, 0, 0, 0);
    border-radius: 0.625rem;
  }

  .popover-viewport {
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;

    ion-list {
      padding: 0.75rem 0rem;

      ion-item {
        --background: transparent;
        --border-color: var(--ion-color-primary-green);
        --max-height: 2.5rem;
        --min-height: 2.5rem;
        --padding-end: 0rem;
        --padding-start: 0.625rem;
        background: transparent;
        color: var(--ion-color-primary-green);
        cursor: pointer;
        margin: 0rem 0.75rem;

        ion-label {
          font-family: 'SF Pro Bold', sans-serif;
          font-size: $font-size-14-px;
          --color: var(--ion-color-black);
        }
      }

      ion-item:hover {
        --background: var(--ion-color-primary-green-hover);
        background: var(--ion-color-primary-green-hover);
        --color: var(--ion-color-white) !important;
        color: var(--ion-color-white) !important;
        border-radius: 0.4rem;
        ion-label {
          font-family: 'SF Pro Bold', sans-serif;
          font-size: $font-size-14-px;
          --color: var(--ion-color-white);
        }
      }
    }
  }

  &::part(arrow) {
    --background: var(--ion-color-primary-green) !important;
  }
  .popover-arrow::after {
    background-color: var(--ion-color-primary-green) !important;
  }
  &::part(content) {
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-primary-green);
    margin-top: -0.0625rem;
    overflow-x: hidden;
    overflow-y: hidden;
    width: $width;
  }
}

@mixin select-interface {
  .select-interface-option {
    --background-activated: var(--ion-color-primary-green-5);
    --background-focused: var(--ion-color-primary-green-5);
    --background-hover: var(--ion-color-primary-green-5);
    --border-style: transparent;
    --color-activated: var(--ion-color-primary-green);
    --color-focused: var(--ion-color-primary-green);
    --color-hover: var(--ion-color-primary-green);
    --inner-padding-end: 0rem;
    --min-height: 2.1875rem;
    --padding-end: 0rem;
    --padding-start: 0.625rem;
    font-size: $font-size-14-px;
    margin: 0rem 0.625rem;
  }

  .select-interface-option:hover {
    --background: var(--ion-color-primary-green-5);
    background: var(--ion-color-primary-green-5);
    border-radius: 0.3125rem;
  }

  ion-radio {
    --color-checked: transparent !important;
  }

  ion-list {
    padding: 0.625rem 0rem;
  }
}
