@import 'popover-mixins';

/*
  PLACE ALL CSS CLASSES FOR IONIC POPOVERS HERE
  MOST USE CASES SHOULD BE ABLE TO USE THE POPOVER STANDARD
  MIXIN. CUSTOM WIDTHS FOR THE POPOVER CAN BE PASSED
  INTO THE MIXIN.
*/

// CUSTOM STYLE POPOVERS (DIFF FONT/SPACING)
.accepted-file-types-popover {
  @include popover-web(18.75rem);
  pointer-events: none;
}

.support-center-popover {
  @include popover-web(15.25rem);
}

.email-info-popover {
  &.desktop {
    --offset-y: -0.25rem;
  }
  &.touch {
    --offset-x: 0.25rem;
    --offset-y: -0.25rem;
  }
  @include popover-web(15.25rem);
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.customer-selection-popover-desktop {
  @include popover-header-web(fit-content);

  &::part(content) {
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-gray-40);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    border-radius: 0.5rem;
    margin-top: 12px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 26.125rem !important;
    padding: 0;
  }

  .popover-viewport {
    max-height: 50rem;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  &::part(backdrop) {
    opacity: 0 !important;
  }
}

.ellipsis-inventory-popover-desktop {
  @include popover-web(15rem);
  @include select-interface;

  // Leave commented
  // @include isDesktop {
  //   @media only screen and (max-width: 1030px) {
  //     zoom: 80% !important;
  //     margin-top: -0.25rem;
  //   }

  //   @media only screen and (max-height: 840px) {
  //     zoom: 80% !important;
  //     margin-top: -0.25rem;
  //   }

  //   @media only screen and (max-height: 650px) {
  //     zoom: 70% !important;
  //     margin-top: -0.25rem;
  //   }
  // }

  .popover-viewport {
    max-height: 28.625rem;

    @include isDesktop {
      @media only screen and (max-height: 830px) {
        max-height: 24rem;
      }
    }

    ion-list {
      padding: 1rem 1rem 0rem;

      p {
        font-family: 'SF Pro Bold';
        font-size: $font-size-14-px;
        margin: 0.5rem 0.75rem 0.75rem 0.5rem;
        color: var(--ion-color-primary-green);
      }

      ion-item {
        --padding-start: 0.75rem;
        --max-height: 2.75rem;
        --min-height: 2.75rem;
        font-family: 'SF Pro Regular';
        margin: 0rem;

        ion-label {
          font-size: $font-size-14-px;
        }

        ion-icon {
          font-size: 0.667rem;
          margin-inline: 0rem 0.667rem;
        }

        &.disabled {
          --color: var(--ion-color-gray-80);
          color: var(--ion-color-gray-80);
        }
      }
    }
  }

  ion-list:last-child {
    padding-bottom: 1rem;
  }
}

.inventory-warning-popover {
  @include popover-web(fit-content);

  @include isDesktop {
    @media only screen and (max-width: 1030px) {
      zoom: 80% !important;
      margin-top: -0.25rem;
    }

    @media only screen and (max-height: 840px) {
      zoom: 80% !important;
      margin-top: -0.25rem;
    }

    @media only screen and (max-height: 650px) {
      zoom: 70% !important;
      margin-top: -0.25rem;
    }
  }

  &::part(content) {
    border: 0.0625rem solid var(--ion-color-secondary-orange);
  }

  &::part(arrow) {
    --background: var(--ion-color-secondary-orange) !important;
  }

  .popover-arrow::after {
    background-color: var(--ion-color-secondary-orange) !important;
  }
}

.recent-purchase-popover {
  @include popover-web(auto);

  &::part(content) {
    --min-width: 18rem !important;
    --max-width: 25rem !important;
  }

  @include isDesktop {
    pointer-events: none; // Prevent flickering behavior on pill hover
  }

  @include isMobile {
    &::part(content) {
      --min-width: 12rem !important;
    }
  }
}

.download-pricing-options-popover {
  @include popover-web(23.75rem);
  @include select-interface;
  --height: 6rem;
  --offset-x: 1.375rem;

  @media screen and (hover: none) and (pointer: coarse) {
    @include isTablet {
      @include popover-web(calc(100vw - 60%));
      --offset-x: 1.375rem;
    }

    @include isMobile {
      @include popover-web(calc(100vw - 2.5rem));
      --offset-x: 4%;
    }
  }
}

.download-order-popover {
  @include popover-web(14rem);
  @include select-interface;
  --max-height: 7.75rem;
  --offset-x: 1.375rem;
}

.download-order-popover-multi {
  @include popover-web(16rem);
  @include select-interface;
  --max-height: 7.75rem;
  --offset-x: 1.375rem;

  @include isDesktop {
    @include popover-web(16rem);

    ion-item::part(native) {
      flex-direction: row-reverse;
    }
  }
}

.download-order-alert {
  .alert-button-inner.sc-ion-alert-ios {
    flex-direction: row-reverse;
  }

  .alert-checkbox-label.sc-ion-alert-ios {
    white-space: normal !important;
  }
}

.print-pricing-options-popover {
  @include popover-web(25rem);
  @include select-interface;
  --height: 6rem;
  --offset-x: 1.25%;

  @media screen and (hover: none) and (pointer: coarse) {
    @include isTablet {
      @include popover-web(
        calc(100vw - 7.5rem)
      ); //tablet padding = 0rem 3.75rem
      --offset-x: 3%;
    }

    @include isMobile {
      @include popover-web(calc(100vw - 2.5rem));
      --offset-x: 4%;
    }
  }
}

.inventory-unit-popover-touch {
  @include popover-web(fit-content);

  @include isTablet {
    @media only screen and (orientation: landscape) {
      margin-top: -0.25rem;

      .popover-viewport {
        max-height: 16.875rem;
      }
    }
  }

  @include isMobile {
    margin-top: -0.25rem;
  }
}

.vendor-selection-popover-desktop {
  @include popover-web(fit-content);
  --offset-y: 0.5rem;
}

.inventory-unit-popover-desktop {
  @include popover-web(fit-content);

  @media only screen and (max-width: 1030px) {
    zoom: 80% !important;
    margin-top: -0.25rem;
  }

  @media only screen and (max-height: 840px) {
    zoom: 80% !important;
    margin-top: -0.25rem;
  }

  @media only screen and (max-height: 650px) {
    zoom: 70% !important;
    margin-top: -0.25rem;
  }
}

.manual-pricing-popover {
  @include popover-web(16rem);
  @include select-interface;
  --max-height: 14rem;

  @include isDesktop {
    @include popover-web(16rem);
    --offset-x: -5rem;
    --offset-y: -5rem;
  }

  @include isDesktopLarge {
    @include popover-web(16rem);
    --offset-x: 5rem;
    --offset-y: 1rem;
  }

  @media only screen and (max-width: 1030px) {
    --offset-x: 0rem;
    --offset-y: -6rem;
  }

  @media only screen and (max-height: 840px) {
    --offset-x: -7rem;
    --offset-y: -6rem;
  }

  @media only screen and (max-height: 650px) {
    --offset-x: -17rem;
  }

  @include isTablet {
    @include popover-web(calc(100vw - 60%));
    --offset-x: 1.375rem;
    --offset-y: -10rem;
  }

  @include isMobile {
    @include popover-web(calc(100vw - 16%));
    --offset-x: 4%;
    --offset-y: -8rem;
  }
}

.force-app-update-popover-tablet,
.force-app-update-popover-mobile {
  --backdrop-opacity: 0.5;
  --height: fit-content;
  --max-height: fit-content;

  &::part(content) {
    background: var(--ion-color-white);
    margin: 0rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.force-app-update-popover-tablet {
  --max-width: 80%;
  --width: 80%;
}

.force-app-update-popover-mobile {
  --max-width: 90%;
  --width: 90%;
}

.addl-instructions-modal {
  @include popover-web(36.875rem);
  @include select-interface;
  --height: 25.75rem;

  &::part(content) {
    --border-radius: 1rem;
  }
}

// DESKTOP ONLY QUERIES
@media only screen and (max-height: 900px) and (hover: hover) {
  .download-pricing-options-popover,
  .print-pricing-options-popover {
    --offset-x: 0.75%;
  }
}

@media only screen and (max-height: 840px) and (hover: hover) {
  .download-pricing-options-popover,
  .print-pricing-options-popover {
    --offset-x: 0.5%;
    zoom: 80%;
  }
}

@media only screen and (max-width: 1030px) and (hover: hover) {
  .download-pricing-options-popover,
  .print-pricing-options-popover {
    zoom: 80%;
  }

  .download-pricing-options-popover {
    --offset-x: 5.55%;
  }

  .print-pricing-options-popover {
    --offset-x: 2%;
  }
}

@media only screen and (max-height: 650px) and (hover: hover) {
  .download-pricing-options-popover {
    zoom: 70%;
  }
}

.warning-popover {
  @include isDesktop {
    --height: 20.125rem;
    --width: 61.5rem;
  }

  @include isTablet {
    --height: 21.25rem;
    --width: 90%;

    .popover-viewport {
      overflow: hidden !important;
    }
  }

  @include isMobile {
    --backdrop-opacity: 0.5;
    --height: 90%;
    --max-width: 97%;
    --width: 97%;
  }

  &::part(content) {
    background: var(--ion-color-white) !important;
    opacity: 1;
  }
}

.wmt-popover {
  --backdrop-opacity: 0.2;

  @media screen and ($isDesktop) {
    --backdrop-opacity: 0;
  }

  ion-backdrop {
    cursor: default;
  }

  .popover-wrapper {
    .popover-arrow {
      display: block !important;
    }

    .popover-arrow::after {
      background-color: var(--ion-color-primary-green) !important;
    }

    .popover-content {
      border: 0.0625rem solid var(--ion-color-primary-green);
      max-width: 10rem;
    }
  }

  &.delivered .popover-wrapper .popover-content {
    max-width: 6rem;
  }
}

.custom-messages-popover-desktop {
  @include popover-web(fit-content);
}

// DEFAULT STYLE POPOVERS
.download-pricing-options-popover-desktop {
  @include popover-web(25rem);
  @include select-interface;
  --offset-x: 0.5rem;
}

.direct-shipping-savings-popover {
  @include popover-web(25rem);
  @include select-interface;
  --offset-x: 0.5rem;
  margin-left: -0.4rem;
}

.ellipsis-options-popover-desktop {
  @include popover-web(fit-content);
}

.notice-message-tooltip {
  @include popover-web(10rem);
  &::part(content) {
    padding: 0.75rem;
  }

  &::part(arrow) {
    --background: var(--ion-color-primary-green) !important;

    &::after {
      background: var(--ion-color-primary-green) !important;
    }
  }
}

.download-list-options-popover-desktop {
  @include popover-web(15rem);

  .popover-viewport {
    overflow-y: scroll;
  }
}

.add-products-popover {
  @include popover-web(16.5rem);
}

.ellipsis-product-options-popover-desktop {
  @include popover-web(auto);

  &::part(content) {
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-primary-green);
    margin-top: -0.0625rem;
    overflow-x: hidden;
    overflow-y: hidden;
    --min-width: 15rem !important;
    --max-width: 25rem !important;
  }
}

.download-inventory-summary-popover {
  @include popover-web(12.5rem);
}

.header-menu-popover-desktop {
  @include popover-header-web(fit-content);

  .popover-viewport {
    max-height: 50rem !important;
  }

  &::part(content) {
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-gray-40);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    border-radius: 0.5rem;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 26.125rem !important;
    padding: 0;
  }

  &::part(backdrop) {
    opacity: 0 !important;
  }
}

.opportunity-popover-desktop {
  @include popover-web(28rem);
  @include select-interface;
}

.position-popover-desktop {
  @include popover-web(9.25rem);
  @include select-interface;
}

.print-pricing-options-popover-desktop {
  @include popover-web(26.025rem);
  @include select-interface;
  --offset-x: 0.625rem;
}

.quantity-units-popover-desktop {
  @include popover-web(11.5rem);
}

.shipping-savings-popover-desktop {
  @include popover-web(21.5rem);
  width: 0;
}

.states-popover-desktop {
  @include popover-web(9.25rem);
  @include select-interface;
  --height: 16.25rem;
  --min-width: 16.25rem;
}

.user-popover-desktop {
  @include popover-header-web(fit-content);

  &::part(content) {
    background: var(--ion-color-white);
    border: 0.0625rem solid var(--ion-color-gray-40);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    box-shadow: 0px 0px 4px 0px rgba(178, 178, 178, 1);
    border-radius: 0.5rem;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 12.5rem !important;
    padding: 0;
    right: -2rem;
  }

  &::part(backdrop) {
    opacity: 0 !important;
  }
}

.vendor-popover-desktop {
  @include popover-web(9.25rem);
  @include select-interface;
  --height: 15.625rem;
  --min-width: 14.375rem;
}

.add-bank-account-popover {
  @include popover-web(42rem);

  .popover-viewport {
    max-height: 16.45rem;
    overflow-y: hidden;
  }
}

.zip-code-change-popover-desktop {
  &::part(content) {
    border: 0.063rem solid var(--ion-color-primary-green);
    width: fit-content !important;
  }

  &::part(arrow) {
    --background: var(--ion-color-primary-green) !important;

    &::after {
      background: var(--ion-color-primary-green) !important;
    }
  }
}

// DESKTOP ONLY QUERIES
@media only screen and (max-width: 1030px) and (hover: hover) {
  .download-pricing-options-popover-desktop,
  .print-pricing-options-popover-desktop {
    zoom: 80%;
  }

  .download-pricing-options-popover-desktop {
    --offset-x: 2.5%;
  }
}

@media only screen and (max-height: 840px) and (hover: hover) {
  .download-pricing-options-popover-desktop,
  .print-pricing-options-popover-desktop {
    zoom: 80%;
  }
}

@media only screen and (max-height: 650px) and (hover: hover) {
  .download-pricing-options-popover-desktop,
  .print-pricing-options-popover-desktop {
    zoom: 70%;
  }
}

.customer-pill-popover {
  @include isDesktop {
    @include popover-header-web(30rem);
  }

  @include isTablet {
    @include popover-web(calc(100vw - 60%));
  }

  @include isMobile {
    @include popover-web(calc(100vw - 16%));
  }

  &::part(content) {
    padding: 1.5rem;
  }
}

.recovery-order-list-options-popover-desktop {
  @include popover-web(15rem);

  .popover-viewport {
    overflow: hidden;
  }
}
