// Needs to be removed from app and refactored

/* Responsive Design Web */
/* larger than xl */
@media only screen and (max-width: 10000px) {
  .hide-xl {
    display: none;
  }

  .show-xl {
    display: inline-block;
  }

  .page-content-outer {
    width: 1200px;
  }
}

/* xl */
@media only screen and (max-width: 1200px) {
  .hide-xl {
    display: none;
  }

  .show-xl {
    display: inline-block;
  }

  .page-content-outer {
    width: 960px;
  }
}

/* lg */
@media only screen and (max-width: 992px) {
  .hide-lg {
    display: none;
  }

  .show-lg {
    display: inline-block;
  }

  .page-content-outer {
    width: 960px;
  }
}

/* md */
@media only screen and (max-width: 768px) {
  .hide-md {
    display: none;
  }

  .show-md {
    display: inline-block;
  }

  .page-content-outer {
    width: 960px;
  }
}

/* sm */
@media only screen and (max-width: 576px) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: inline-block;
  }

  .page-content-outer {
    width: 960px;
  }
}

/* xs */
@media (max-width: 576px) {
  .hide-xs {
    display: none;
  }

  .show-xs {
    display: inline-block;
  }

  .page-content-outer {
    width: 960px;
  }
}

@media only screen and (min-device-width: 1025px) {
  .hide-desktop {
    display: none !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  body {
    overflow-x: hidden;
    position: fixed;
  }

  .hide-tablet {
    display: none !important;
  }

  ion-button.button-lg {
    width: 345px;
  }
}

@media only screen and (max-device-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}
