/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Parameters */
@import 'assets/styles/breakpoints';
@import 'assets/styles/font-sizes';
@import 'assets/styles/spacing';

/* Power reviews */
@import 'assets/styles/power-reviews';

/* Material */
@import 'assets/styles/material/mat-radio';
@import 'assets/styles/material/mat-expansion';
@import 'assets/styles/material/mat-calendar';

/* Global Ionic Components */
@import 'assets/styles/grid/grid';
@import 'assets/styles/buttons/buttons';
@import 'assets/styles/popovers/popover';
@import 'assets/styles/searchbar/searchbar';
@import 'assets/styles/toolbar/ion-toolbar';
@import 'assets/styles/modals/modals';
@import 'assets/styles/tooltip/tooltips';
@import 'assets/styles/actionsheets/actionsheets';
@import 'assets/styles/scrollbar/modal-scrollbar.scss';
@import 'assets/styles/searchbar/searchbar';
@import 'assets/styles/toast/toast';
@import 'assets/styles/accordion/accordion';
@import 'assets/styles/radio/radio';
@import 'assets/styles/item/item';
@import 'assets/styles/refresher/refresher';
@import 'assets/styles/calendar/calendar';
@import 'assets/styles/flyouts/flyout';
@import 'assets/styles/animations';

/* Global Other CSS */
@import 'assets/styles/common/common-page-styles';
@import 'assets/styles/common/other';
@import 'assets/styles/common/virtual-scroll';
@import 'assets/styles/common/loading-screen';
@import 'assets/styles/common/back-to-top-button';
@import 'assets/styles/usabilla/usabilla';
@import 'assets/styles/dragdrop/dragdrop';
@import 'assets/styles/note/note';

// NO CSS IN THIS FILE - CREATE FILE IN ASSETS/STYLES FOLDER
