@mixin modal-desktop($height, $width) {
  &::part(content) {
    border-radius: 1rem;
    height: $height !important;
    width: $width !important;
    min-height: $height;
  }

  // /* MODAL ZOOM ON SMALLER SCREEN RESOLUTIONS */
  // @media only screen and (max-width: 1030px) {
  //   zoom: 80% !important;
  // }

  // @media only screen and (max-height: 840px) {
  //   zoom: 80% !important;
  // }

  // @media only screen and (max-height: 650px) {
  //   zoom: 70% !important;
  // }
}

@mixin responsive-modal-desktop($minHeight, $maxHeight, $minWidth, $maxWidth) {
  &::part(content) {
    border-radius: 1rem;
    min-width: $minWidth !important;
    max-width: $maxWidth !important;
    max-height: $maxHeight !important;
    min-height: $minHeight !important;
    --height: 100%;
  }
}

@mixin modal-desktop-shadow($height, $width) {
  &::part(content) {
    border-radius: 1rem;
    height: $height !important;
    width: $width !important;
    min-height: $height;
    box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5) !important;
  }
}
/* ----- MODAL HEADERS --------- */
ion-header.ion-header-modal-desktop,
ion-header.ion-header-modal-tablet,
ion-header.ion-header-modal-mobile,
ion-header.ion-header-modal-mobile-virtual-scroll,
ion-header.ion-header-modal-tablet-virtual-scroll {
  ion-toolbar {
    ion-label {
      color: var(--ion-color-black);
      cursor: default;
      font-family: 'SF Pro Bold';
      font-size: $font-size-20-px;
    }
  }
}

ion-header.ion-header-modal-desktop {
  ion-toolbar {
    ion-buttons {
      position: absolute;
      right: 0.9375rem;
      top: 1.25rem;

      ion-button {
        --background: transparent !important;
        --background-activated: transparent !important;
        --background-focused: transparent !important;
        --background-hover: transparent !important;
        --ripple-color: transparent;

        ion-icon[slot='icon-only'] {
          --ionicon-stroke-width: 2.1875rem;
          color: var(--ion-color-primary-green);
          cursor: pointer;
          flex-grow: 0;
          flex-shrink: 0;
          font-size: $font-size-35-px !important;
          z-index: 1;
        }
      }
    }
  }
}

ion-header.ion-header-modal-tablet,
ion-header.ion-header-modal-mobile,
ion-header.ion-header-modal-mobile-virtual-scroll,
ion-header.ion-header-modal-tablet-virtual-scroll {
  ion-toolbar {
    --padding-end: 0rem;
    --padding-start: 0rem;
    --padding-top: 0rem;
    padding: 0rem !important;

    ion-buttons {
      ion-back-button {
        --color: var(--ion-color-black);
        --icon-font-size: 1.5625rem;
        --icon-margin-end: 0.9375rem;
      }
    }
  }
}

ion-header.ion-header-modal-tablet {
  padding: 2.1875rem 3.75rem 1.875rem;
}

ion-header.ion-header-modal-tablet-virtual-scroll {
  padding: 2.1875rem 3.75rem 0 3.75rem;
}

ion-header.ion-header-modal-mobile {
  padding: 1.875rem 1rem 1rem;
}

ion-header.ion-header-modal-mobile-virtual-scroll {
  padding: 1.875rem 1rem 0 1rem;
}

ion-button.touch-exit-btn {
  --background: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
  --ripple-color: transparent;
  --box-shadow: none !important;
  position: absolute;
  right: 0.9375rem;
  top: 1.25rem;
  z-index: 10;

  ion-icon[slot='icon-only'] {
    --ionicon-stroke-width: 2.1875rem;
    color: var(--ion-color-primary-green);
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: $font-size-35-px !important;
  }
}

/* -------- MODAL CONTENT --------- */
ion-content.ion-content-modal-tablet,
ion-content.ion-content-modal-mobile {
  ion-item-divider {
    --background: var(--ion-color-white);
    border-bottom: 0.0625rem solid var(--ion-color-gray-60);
    min-height: 0rem;
  }
}

ion-content.ion-content-modal-tablet {
  --padding-bottom: 3.125rem;
  --padding-end: 3.75rem;
  --padding-start: 3.75rem;
  --padding-top: 0.3125rem;
}

ion-content.ion-content-modal-mobile {
  --padding-bottom: 1.875rem;
  --padding-end: 1.25rem;
  --padding-start: 1.25rem;
  --padding-top: 0.3125rem;
}

ion-content.ion-content-modal-mobile-virtual-scroll,
ion-content.ion-content-modal-tablet-virtual-scroll {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}

/* -------- MODAL FOOTERS --------- */
ion-footer.ion-footer-modal {
  ion-toolbar {
    --background: var(--ion-color-white);
    --border-style: none;
    --padding-bottom: 1.25rem;
    --padding-top: 1.25rem;
    box-shadow: 0rem -0.0938rem 0.3125rem 0rem rgba(47, 47, 47, 0.15);

    ion-buttons {
      justify-content: center;

      ion-button:first-of-type {
        margin-right: 0.625rem;
      }

      ion-button:last-of-type {
        margin-left: 0.625rem;
      }
    }
  }

  p {
    color: var(--ion-color-black);
    font-family: 'SF Pro Bold';
    margin: 0rem 1.25rem 0.625rem;
    text-align: center;

    span {
      font-family: 'SF Pro Regular';
    }
  }
}

ion-footer.ion-footer-modal-desktop {
  ion-toolbar {
    --padding-bottom: 1.875rem;
    --padding-top: 1.875rem;
  }
}
