.mat-calendar {
  margin: 0 auto;
  width: 90%;
}
.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-comparison-identical) {
  border: none;
  box-shadow: none;
  font-weight: bold;
  color: var(--ion-color-primary-green) !important;
}

.mat-calendar-body-selected:not(.mat-calendar-body-comparison-identical) {
  background-color: var(--ion-color-primary-green);
  border: none;
  color: var(--ion-color-white) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: var(--ion-color-gray-20);
}

.mat-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 0 !important;
  border-radius: 4px;
}

.mat-calendar-body-label {
  opacity: 0;
}

.mat-calendar-body-label[colspan='7'] {
  display: none;
}

.available-delivery-date-color {
  background-image: url('../../images/green-circle-50.png');
  background-repeat: no-repeat;
  background-position: center;
}

.mat-calendar-body-in-range::before {
  background: var(--ion-color-primary-green-20) !important;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  body {
    overflow-x: hidden;
    position: fixed;
  }

  .mat-card {
    padding: 0 !important;
  }

  .available-delivery-date-color {
    background-image: url('../../images/green-full-circle-41.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}

#will-call-calendar {
  .mat-calendar-content {
    padding: 0 !important;
  }
}

#my-orders-filters-calendar-mobile {
  @include isTablet {
    width: 50%;
  }
  .mat-calendar-header {
    padding: 0rem;

    .mat-calendar-controls {
      button {
        padding: 0rem;
      }
    }
  }
}
