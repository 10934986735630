// Moving Truck Animation - Minimum Order Met
$start: -3.125rem;
$end: 18rem;

@keyframes wheels {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes truck-movement {
  0% {
    transform: translate($start, 0);
  }
  100% {
    transform: translate($end, 0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translate(100, 0);
  }
}

// Horizontal Scrolling Bounce Animation - Filter Banner and Tabs
@keyframes horizontalBounce {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(15px);
  }
  50% {
    transform: translateX(-10px);
  }
  70% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.add-bounce {
  animation: horizontalBounce 0.5s ease forwards;
}

.shrink-expand {
  animation: shrinkExpand 400ms ease-out; /* Adjust as needed */
}

@keyframes shrinkExpand {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
