[hidden] {
  display: none !important;
}

// need global targeting to deal with placeholder that is wider than div
ion-input.comments-input-mobile
  .input-wrapper
  .native-wrapper
  .native-input::placeholder {
  white-space: pre-line;
}

ion-input.comments-input-mobile .input-wrapper .native-wrapper .native-input {
  min-height: 3rem;
}

ion-input.comments-input-mobile.has-value
  .input-wrapper
  .native-wrapper
  .native-input {
  min-height: auto;
}
